<template>
  <div>
    <v-autocomplete
      :value="values"
      label="Merchants"
      :items="itemsFiltered"
      clearable
      @input="set"
      item-text="name"
      item-value="id"
      multiple
      class="mb-0"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption">(+{{ values.length - 1 }})</span>
      </template>
      <template v-slot:append-outer v-if="enablePlot">
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon color="primary" @click="plot">mdi-map-marker</v-icon>
        </v-slide-x-reverse-transition>
      </template>
      <template v-slot:append-outer v-if="enableSelectAll">
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon color="primary" @click="selectAll">mdi-select-all</v-icon>
        </v-slide-x-reverse-transition>
      </template>      
    </v-autocomplete>
    <!-- <span class="ma-0" style="font-size:12px">{{ filteredText }}</span> -->

    <!-- <v-progress-linear indeterminate color="yellow darken-2" v-if="loading"></v-progress-linear> -->
  </div>
</template>

<script>
import merchantService from "@/services/MerchantService";
import merchantpopup from "@/components/leaflet/popups/Merchant.vue";
//pass to dynamically loaded components
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import store from "@/store/store";

export default {
  props: {
    enablePlot: { default: false, type: Boolean },
    plotOnSelection: {default: false, type: Boolean},
    enableSelectAll: { default: false, type: Boolean },
  },
  data: () => ({
    loading: false,
    items: []
  }),
  watch: {
    merchantTypes(val) {
      if (val.length == 0) return;
      this.set(this.itemsFiltered.map(i => i.id));
    }
  },
  computed: {
    values() {
      return this.$store.getters["filter/filterRequest"].merchants;
    },
    merchantTypes() {
      return this.$store.getters["filter/filterRequest"].merchantTypes;
    },
    itemsFiltered() {
      if (this.merchantTypes.length > 0) {
        let filtered = this.items.filter(i =>
          this.merchantTypes.includes(i.merchantTypeId)
        );
        return filtered;
      }
      return this.items;
    },
    filteredText() {
      return `Showing ${this.itemsFiltered.length} of ${this.items.length}`;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.items = await merchantService.GetMerchants();
      this.loading = false;
    },
    async set(val) {
      this.$store.dispatch("filter/setMerchants", val);
      if(this.plotOnSelection)
        await this.plot();
    },
    selectAll(){
      let values = this.itemsFiltered.map(i => i.id);
      this.$store.dispatch("filter/setMerchants", values);
    },
    async plot() {
      this.loading = true;
      Window.merchantFeatureGroup.clearLayers();
      const selected = this.items.filter(i => this.values.includes(i.id));
      const selectedIds = selected.map(i => i.id);
      const fc = await merchantService.GetMerchantsFeatureCollection(
        selectedIds
      );

      window.L.geoJSON(fc, {
        onEachFeature: feature => {
          let lat = feature.geometry.coordinates[1];
          let lon = feature.geometry.coordinates[0];

          var myIcon = window.L.icon({
            iconUrl: feature.properties.featureImageUrl,
            iconSize: [25, 30],
            iconAnchor: [22, 30],
            popupAnchor: [-3, -76]
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [30, 30],
            // shadowAnchor: [22,29]
          });

          let newLayer = window.L.marker([lat, lon], { icon: myIcon });
          Window.merchantFeatureGroup.addLayer(newLayer);

          this.addMerchantPopupToMarker(newLayer, feature);
        }
      });

      this.loading = false;
    },
    addMerchantPopupToMarker(layer, feature) {
      let ComponentClass = Vue.extend(merchantpopup);
      let lpop = new ComponentClass({
        vuetify,
        store: store,
        propsData: {
          layer: layer,
          title: feature.properties.featureName,
          merchantId: feature.properties.featureId
        }
      }).$mount().$el;

      layer.bindPopup(lpop, {
        minWidth: "600"
      });

      layer.on("click", async () => {
        // eslint-disable-next-line no-prototype-builtins
        if(lpop.__vue__.hasOwnProperty("getOverview"))
          lpop.__vue__.getOverview();
      });
    }
  }
};
</script>