<template>
  <v-btn small type="primary" @click="create" color="primary" :loading="loading" width="150">
      Apply filter
  </v-btn>
</template>


<script>
import TargetService from "@/services/TargetService"



export default {
  data: () => ({
    loading: false,
    items: [],
    value: null,
    errors: []
  }),
  computed: {
    filterRequest() {
      return this.$store.getters["filter/filterRequest"];
    },
    isSegmentFilterActive(){
        return this.filterRequest.segments.length > 0
    },
    isSalesrepFilterActive(){
        return this.filterRequest.salesreps.length > 0
    }    
  },
  methods: {
      async create(){
        
        Window.heatmapFeatureGroup.clearLayers()
        Window.organizationsClusterGroup.clearLayers();
        this.$store.dispatch("util/setLoading", {visible: true, text: "Applying target filter"});
        this.loading = true
        this.$store.dispatch("filter/setResult", null)
          const request = this.filterRequest;
          const response = await TargetService.CreateSelection(request)
          if(!response.succeeded){
              this.errors = response.errors
              const snackbar = { show: true, message: "Ett fel inträffade", errors: response.errors  }
              this.$store.dispatch("util/setSnackbar", snackbar)
              this.loading = false
              this.$store.dispatch("util/resetLoading");
              return
          }


          var targetInfo = await TargetService.GetTargetInfo(response.newId);
          this.$store.dispatch("filter/setResult", targetInfo)

          this.loading = false
          this.$store.dispatch("util/resetLoading");


          this.$store.dispatch("target/updateAreas", response.newId);
      }
  },
};
</script>