<template>
  <v-card :loading="loading" flat>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-card-text>
       <v-simple-table dense class="merchant-summary-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" style="font-size:10px">
            
          </th>
          <th class="text-left" style="font-size:10px">
            Segment
          </th>
          <th class="text-left" style="font-size:10px">
            R12
          </th>
          <th class="text-left" style="font-size:10px">
            YTD
          </th>          
          <th class="text-left" style="font-size:10px">
            LYTD
          </th>          
          <th class="text-left" style="font-size:10px">
            LY
          </th>  
          <th class="text-left" style="font-size:10px">
            Customers R12
          </th>                                              
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in overview"
          :key="i"
          style="font-size:6px"
          @click="toggleSelected(item)"
          :active="true"
        >
          <td style="font-size:10px">
              <v-simple-checkbox :ripple="false" :value="isSelected(item)" @input="toggleSelected(item)">
              </v-simple-checkbox>
          </td>
          <td style="font-size:10px">{{ item.dimensionName }}</td>
          <td style="font-size:10px">{{ item.r12_Formatted }}</td>
          <td style="font-size:10px">{{ item.ytd_Formatted }}</td>
          <td style="font-size:10px">{{ item.lytd_Formatted }}</td>
          <td style="font-size:10px">{{ item.ly_Formatted }}</td>
          <td style="font-size:10px">{{ item.customersActive_Formatted }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
    </v-card-text>
    <v-card-text>
      <v-alert elevation="4" dense color="green lighten-4" >
        <div class="transition-swing text-caption">Process selected rows</div>
      <v-btn color="info" x-small @click="plotOnMap" :disabled="selected.length == 0">
        Plot on map
      </v-btn>
      <v-btn color="info" x-small @click="exportSummary" :disabled="selected.length == 0" class="ml-2">
        Export
      </v-btn>
      </v-alert>
      <!-- only declare to use REF.create() -->
        <createtargetbutton v-show="false"  ref="targetCreate" /> 
        <targetactionsmenu v-show="false"  ref="targetActions" /> 
    </v-card-text>
    <v-card-actions>
 
        <v-spacer />
        <v-btn small  color="info" @click="dashboard" >
          <v-icon left small>mdi-chart-bar</v-icon>
          Dashboard
        </v-btn>
        <v-btn small  color="info" @click="report" class="ml-2">
          <v-icon left small>mdi-table</v-icon>
          Report
        </v-btn>        
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.merchant-summary-table{

  
  table-layout: fixed;
  width: 660px;
  font-size:6px;
  white-space: nowrap
}

.merchant-summary-table > th, td {
  width: 1px;
  overflow: hidden;
}
</style>

<script>
import targetService from "@/services/TargetService";

import createtargetbutton from "@/components/filters/CreateTargetButton.vue";
import targetactionsmenu from "@/components/filters/TargetActionsMenu.vue";

export default {
  components:{
    createtargetbutton , targetactionsmenu
  },
  props: {
    layer: { default: null, type: Object },
    merchantId: { default: null, type: String },
    title: { default: null, type: String },
  },
  data: () => ({
    loading: false,
    selected:[],
    overview: [],
  headers: [
            {
              text: 'Segment',
              align: 'start',
              sortable: false,
              value: 'dimensionName',
            },
            { text: 'R12', value: 'r12_Formatted' },
            { text: 'YTD', value: 'ytd_Formatted' },
            { text: 'LYTD', value: 'lytd_Formatted' },
            { text: 'LY', value: 'ly_Formatted' },
            { text: 'Customers', value: 'customersActive_Formatted' },
          ],    
  }),
  computed: {
    targetId() {
      const targetInfo = this.$store.getters["filter/result"];
      if(targetInfo !== null)
        return targetInfo.id;
      else
        return null
    }
  },
  async mounted() {
    // await targetService.GetMerchantStatistics(this.merchantId, this.targetId);
  },
  methods: {

    isSelected(item){
      return this.selected.some((i) => i.dimensionId == item.dimensionId)
    },
    toggleSelected(item){

      if(this.isSelected(item)){
        let filtered = this.selected.filter((i) => { return i.dimensionId !== item.dimensionId }); 
        this.selected = filtered
      }
      else {
        this.selected.push(item)
      }
    },

    async exportSummary(){
          await this.applyFilter()
          await this.$refs.targetActions.showExportSummary()
    },
    async plotOnMap(){
      await this.applyFilter()
      await this.$refs.targetActions.plotOrganizationsOnMap()
    },
    async applyFilter(){
        this.$store.dispatch("filter/setSegments", this.selected.map(i => i.dimensionId))
        this.$store.dispatch("filter/setMerchants", [this.merchantId])
        await this.$refs.targetCreate.create()
    },
    async getOverview(){
      this.loading = true
      this.overview = await targetService.GetMerchantStatistics(this.merchantId, this.targetId);
      this.loading = false
    },
    async dashboard() {
      this.$store.dispatch("chart/reset");
      this.$store.dispatch("util/setBottomChartSheet", false);
      this.loading = true;
      let charts = null;
      charts = await targetService.GetMerchantStatisticsChart(this.merchantId, this.targetId);

      this.$store.dispatch("chart/setCharts", charts);

      this.$store.dispatch("util/setBottomChartSheet", true);
      this.loading = false;
    },
    async report() {
      this.$store.dispatch("chart/reset");
       this.$store.dispatch("util/setBottomChartSheet", false);
      this.loading = true;
      let report = await targetService.GetMerchantProductSalesReport(this.merchantId, this.targetId);

      this.$store.dispatch("chart/setMerchantProductReport", report);
      this.$store.dispatch("util/setBottomChartSheet", true);
      this.loading = false;
    },    
  },
};
</script>