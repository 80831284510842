<template>
<v-card>
  <v-card-subtitle>
    {{ resultText }}
  </v-card-subtitle>
  <v-card-actions>
    <v-spacer />
  <v-menu
      rounded
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
        color="success"
        small
          v-bind="attrs"
          v-on="on"
          width="150"
          :disabled="!result"
        >
          Actions
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="plotOrganizationsOnMap">
          <v-list-item-title>Plot on map</v-list-item-title>
        </v-list-item>
        <v-list-item @click="plotVisitedMerchantsOnMap" >
          <v-list-item-title>Plot visited merchants</v-list-item-title>
        </v-list-item>
        <v-list-item @click="showSalesReport">
          <v-list-item-title>Sales report</v-list-item-title>
        </v-list-item>   
        <v-list-item @click="showExportSummary">
          <v-list-item-title>Export</v-list-item-title>
        </v-list-item>                                
      </v-list>
    </v-menu>
  </v-card-actions>
  <v-card-text>
  <v-checkbox v-model="showHeatmap" label="Heatmap" @change="plotHeatmap" :disabled="!result">
  </v-checkbox>
  </v-card-text>
</v-card>
</template>



<script>
import TargetService from "@/services/TargetService";


import merchantpopup from "@/components/leaflet/popups/Merchant.vue";
//pass to dynamically loaded components
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import store from "@/store/store";
import HeatmapOverlay from "leaflet-heatmap/leaflet-heatmap.js";

//leaflet L
const L = window.L;


export default {
  data: () => ({
    loadingOrganizationsFC: false,
    loadingMerchantsFC: false,
    items: [],
    value: null,
    errors: [],
    showHeatmap: false
  }),
  computed: {
    filterRequest() {
      return this.$store.getters["filter/filterRequest"];
    },
    result() {
      return this.$store.getters["filter/result"];
    },
    resultText() {
      if(!this.result)
        return "Target has not been set";

      return `Targeting ${this.result.recipients} ${
        this.result.lifecycleType == 1 ? "customers" : "prospects"
      } `;
    },
  },
  methods: {
    

    async plotOrganizationsOnMap() {
      this.$store.dispatch("util/setLoading", {visible: true, text: "Fetching organization markers"});
      this.loadingOrganizationsFC = true;
      let geojson = await TargetService.GetTargetFeatureCollection(this.result.id);
      FillClusterGroup(geojson, Window.organizationsClusterGroup)
      this.loadingOrganizationsFC = false;
      this.$store.dispatch("util/resetLoading");
    },
      async plotHeatmap(){
      
      Window.heatmapFeatureGroup.clearLayers()
      if(!this.showHeatmap)
        return

      this.$store.dispatch("util/setLoading", {visible: true, text: "Fetching heatmap"});
      let data = await TargetService.GetTargetSalesHeatmap(this.result.id);
      fillHeatmap(data, Window.heatmapFeatureGroup)
      this.$store.dispatch("util/resetLoading");
      },
    async plotVisitedMerchantsOnMap() {
      Window.merchantFeatureGroup.clearLayers()
      this.loadingMerchantsFC = true;
      this.$store.dispatch("util/setLoading", {visible: true, text: "Fetching visited merchants"});
      var fc = await TargetService.GetTargetVisitedMerchantsFeatureCollection(this.result.id);
      
      window.L.geoJSON(fc, {
        onEachFeature: feature => {
          let lat = feature.geometry.coordinates[1];
          let lon = feature.geometry.coordinates[0];

          var myIcon = window.L.icon({
            iconUrl: feature.properties.featureImageUrl,
            iconSize: [25, 30],
            iconAnchor: [22, 30],
            popupAnchor: [-3, -76]
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [30, 30],
            // shadowAnchor: [22,29]
          });

          let newLayer = window.L.marker([lat, lon], { icon: myIcon });
          Window.merchantFeatureGroup.addLayer(newLayer);

          this.addMerchantPopupToMarker(newLayer, feature);
        }
      });

      this.loadingMerchantsFC = false;
      this.$store.dispatch("util/resetLoading");



    },    
    showExportSummary(){
      const modalComponent = {
        show: true,
        component: "exporttargetsummary",
        width: "90%"
      };
      this.$store.dispatch("util/setModalComponent", modalComponent);

    },
    showSalesReport(){
      const modalComponent = {
        show: true,
        component: "merchantproductsalesreportnested",
        width: "60%"
      };
      this.$store.dispatch("util/setModalComponent", modalComponent);
    },
    addMerchantPopupToMarker(layer, feature) {
      let ComponentClass = Vue.extend(merchantpopup);
      let lpop = new ComponentClass({
        vuetify,
        store: store,
        propsData: { layer: layer, title: feature.properties.featureName, merchantId: feature.properties.featureId  }
      }).$mount().$el;

      layer.bindPopup(lpop, {
        minWidth: "200"
      });

      layer.on("click", async e => {
        lpop.__vue__.getOverview();
        console.log(e);
      });
    }    
  },
};

    var fillHeatmap = function(n)  {
      let max = Math.max.apply(
        Math,
        n.map(function(i) {
          return i.weight;
        })
      );

      let data = {
        max: max,
        data: n
      };

      let cfg = {
        radius: 0.6,
        maxOpacity: 0.8,
        scaleRadius: true,
        useLocalExtrema: false,
        latField: "latitude",
        lngField: "longitude",
        valueField: "weight"
      };

      let heatmapLayer = new HeatmapOverlay(cfg);
      Window.heatmapFeatureGroup.addLayer(heatmapLayer);

      // heatmapFeatureGroup.addTo(Window.leafletmap);
      heatmapLayer.setData(data);
    }

var FillClusterGroup = function(fc, clustergroup) {
      clustergroup.clearLayers();
      L.geoJSON(fc, {
        onEachFeature: function(feature, layer) {
       
          var myIcon = window.L.icon({
            iconUrl: feature.properties.featureImageUrl,
            iconSize: [25, 30],
            iconAnchor: [22, 30],
            popupAnchor: [-3, -76]
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [30, 30],
            // shadowAnchor: [22,29]
          });

          let lat = feature.geometry.coordinates[1];
          let lon = feature.geometry.coordinates[0];
          
          layer = L.marker([lat, lon], { icon: myIcon }).bindPopup("<p>" + feature.properties.featureName + "</p>");
          clustergroup.addLayer(layer);
        }
      });
    }



</script>